.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}




/* service cards  */

.flip-card {
  perspective: 1000px;
  width: 100%;
  height: 250px; /* Set consistent height for the card */
}

.flip-card-inner {
  position: relative;
  width: 100%;
  height: 100%; /* Inherit height from parent */
  text-align: center;
  transition: transform 0.6s;
  transform-style: preserve-3d;
}

.flip-card:hover .flip-card-inner {
  transform: rotateY(180deg);
}

.flip-card-front,
.flip-card-back {
  position: absolute;
  width: 100%;
  height: 300px; /* Inherit height from parent */
  backface-visibility: hidden;
}

.flip-card-front {
  z-index: 2;
}

.flip-card-back {
  transform: rotateY(180deg);
 
}
.slick-track {
  display: flex;
  gap: 20px; /* Adjust gap as needed */
}

.slick-slide {
  display: flex;
  justify-content: center;
  padding: 10px 10px;
}



/* // team cards */

.zoom-card {
  transition: transform 0.3s ease-in-out; /* Smooth zoom effect */
}

.zoom-card:hover {
  transform: scale(1.1); /* Slightly enlarge the card */
}